<template lang="pug">
.business-page(@scroll.lazy="checkSelected")
  page-block-title(title="營業項目",
    imageSrc="/images/title/business.png")
  .bg-image
  .business-list.flex.align-start.pt-64.justify-center
    .anchors-container.flex.column.align-center.pb-20
      .anchors.flex.column.align-start.gap-20
        a.anchor.clickable.flex.align-center.gap-12(
          :class="{ selected: selected === b.title }",
          :href="`#${b.title}`",
          v-for="b in businesses",
          :key="b.title"
        )
          .selected-icon
          span {{ b.title }}
    .businesses.flex.column.align-start.gap-32.pb-180
      .business.flex.column.p-24.text-left(
        ref="business",
        v-for="b in businesses",
        :key="b.title"
        :class="{ 'expanded': b.expanded }"
      )
        .card-row.flex.align-center.gap-40.text-left
          .anchor-node(:id="b.title")
          .empty-image(v-if="b.image === ''")
          img.image(v-else, :src="b.image")
          .text.flex.column.gap-24
            .title {{ b.title }}
            .desc {{ b.description }}
          .expand.flex.align-center.clickable(
            @click="b.expanded = !b.expanded", v-if="b.subImages?.length > 0")
            .expand-icon(:class="{ 'expanded': b.expanded }")
        .expand-images.flex.column.align-start
          img.image(v-for="sub in b.subImages", :src="sub")
  page-footer
</template>

<script>
import { mapMutations } from 'vuex';
import { GetCompanyBusiness } from '@/api/company';

export default {
  data() {
    return {
      businesses: [],
      selected: '',
    };
  },
  methods: {
    ...mapMutations(['setAnimateFinish']),
    async loadData() {
      const rsp = await GetCompanyBusiness();
      this.businesses = rsp.data.map((d) => ({
        ...d,
        image: d.image === '' ? '' : `/api/image/${d.image}`,
        subImages: (d.subImages || []).map((sub) => (sub === '' ? '' : `/api/image/${sub}`)),
        expanded: false,
      }));
      this.selected = this.businesses[0]?.title || '';
    },
    checkSelected() {
      for (let idx = 0; idx < this.businesses.length; idx += 1) {
        const rect = this.$refs.business[idx].getBoundingClientRect();
        if (rect.y >= 0) {
          this.selected = this.businesses[idx].title;
          break;
        }
      }
    },
  },
  mounted() {
    this.$setTitle('頂鑫科技工程有限公司/宥陞工程有限公司 - 營業項目');
    this.loadData();
    this.setAnimateFinish(true);
  },
};
</script>

<style lang="scss" scoped>
.business-page {
  position: relative;
  .bg-image {
    position: sticky;
    top: 0;
    height: 100vh;
    width: 100vw;
    margin-bottom: -100vh;
    background-image: url('/bg.png');
    background-size: cover;
  }
}
.business-page {
  overflow-y: scroll;
  overflow-y: overlay;
  overflow-x: hidden;
  width: 100vw;
  height: calc(100vh - 80px);
  .business-list {
    .anchors-container {
      position: -webkit-sticky;
      position: sticky;
      top: 20px;
      flex: 0 1 372px;
      .anchors {
        position: sticky;
        top: 100px;
        a {
          color: #282825;
          text-decoration: none;
          transition: all 0.3s ease-in-out;
          &:hover {
            color: #bacf39;
          }
          .selected-icon {
            width: 16px;
            height: 16px;
          }
          &.selected {
            .selected-icon {
              background-image: url("/images/focus-icon.png");
            }
          }
        }
      }
    }
    .businesses {
      flex: 0 1 720px;
      .business {
        border: 1px solid  #DEDDDD;
        background: white;
        border-radius: 16px;
        position: relative;

        &:hover {
          background:#F3F6DE;
        }
        .anchor-node {
          position: absolute;
          top: -20px;
        }
        .empty-image {
          min-width: 320px;
          max-width: 360px;
          min-height: 180px;
          max-height: 203px;
          background: #8c8c8c;
        }
        .image {
          object-fit: cover;
          min-width: 320px;
          max-width: 360px;
          min-height: 180px;
          max-height: 203px;
        }
        .text {
          .title {
            color: var(--text-color, #282825);
            font-family: Noto Sans TC;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.9px;
          }
          .desc {
            color: var(--text-color, #282825);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 180%; /* 25.2px */
            letter-spacing: 0.14px;
          }
        }

        .expand {
          flex: 0 0 60px;
          .expand-icon {
            border: 1px solid #bacf39;
            border-radius: 30px;
            height: 60px;
            width: 60px;
            position: relative;
            &::before {
              content: " ";
              position: absolute;
              background: #bacf39;
              width: 24px;
              height: 2px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            &::after {
              content: " ";
              position: absolute;
              background: #bacf39;
              width: 24px;
              height: 2px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(90deg);
              transition: all 0.3s ease-in-out;
            }
            &.expanded {
              &::after {
                transform: translate(-50%, -50%);
              }
            }
          }
        }

        .expand-images {
          overflow: hidden;
          transition: all .3s ease-in-out;
          .image {
            transition: all .3s ease-in-out;
            min-height: 0;
            max-height: 0;
            height: 0;
            overflow: hidden;
          }
        }
        &.expanded {
          .expand-images {
            margin-top: 20px;
            gap: 18px;
            .image {
              min-width: 647px;
              max-width: 647px;
              min-height: 364px;
              max-height: 364px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .business-page {
    .business-list {
      flex-wrap: wrap;
      .anchors-container {
        position: relative;
        flex: 0 0 100vw !important;
        .anchors {
          flex-direction: row;
          flex-wrap: wrap;
          gap: 20px;
          margin: 20px;
          .anchor {
            flex: 0 0 auto;
          }
        }
      }
      .businesses {
        padding: 20px;
        .business {
          .card-row {
            flex-wrap: wrap;
            gap: 20px !important;
            justify-content: flex-end;
          }
          .image {
            width: 100%;
          }
          .empty-image {
            flex: 0 0 100%;
            width: 100%;
          }
          .text {
            gap: 12px !important;
          }
          &.expanded {
            .expand-images {
              margin-top: 20px;
              .image {
                min-width: 100% !important;
                max-width: 100% !important;
                min-height: 50vw !important;
                max-height: 50vw !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>
